import { ReactNode } from 'react';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import {
  PageViewTracker,
  BASE_PATHS,
  INTERCOM_CONFIG,
  getRandomSampleFromArray,
  futureUnixTimestamp,
  schemaData,
  COOKIES_CONFIG,
} from '@belong/common';
import {
  Text,
  TestimonialsCarousel,
  BulletList,
  LinkBase,
  Footer,
  BelongExplainerVideo,
  Image,
  Link,
} from '@belong/ui';
import clsx from 'clsx';
import { kebabCase } from 'es-toolkit/compat';
import { parseCookies } from 'nookies';
import { fetchCityListService } from 'src/api/geo';
import { fetchFeaturedCitiesService, setFeaturedCitiesService } from 'src/api/homes';
import { FeaturedCities } from 'src/api/models';
import { Header } from 'src/components/header/header';
import { IntercomWidgetProps } from 'src/components/intercom-widget/intercom-widget';
import { MetaSEO } from 'src/components/meta-seo/meta-seo';
import { RatingRotator } from 'src/components/rating-rotator/rating-rotator';
import { FaqSection } from 'src/features/home-page/components/FaqSection/FaqSection';
import { ReferralCard } from 'src/features/home-page/components/ReferralCard/ReferralCard';
import { Hero } from 'src/features/home-page/components/hero/hero';
import { SocialProof } from 'src/features/home-page/components/social-proof/social-proof';
import { HOME_PAGE_STRINGS } from 'src/features/home-page/home-page.strings';
import styles from 'src/features/home-page/home.module.css';
import { StaticLandingPageLink } from 'src/features/homeowners/homeowners.types';

const IntercomWidget = dynamic<IntercomWidgetProps>(
  () => import('src/components/intercom-widget/intercom-widget').then((mod) => mod.IntercomWidget),
  { ssr: false }
);

const metaDescription = HOME_PAGE_STRINGS['meta.description'] as string;
const metaTitle = HOME_PAGE_STRINGS['meta.title'] as string;

type HomePageProps = { featuredSet: StaticLandingPageLink[] };

export default function IndexPage({ featuredSet }: HomePageProps) {
  return (
    <PageViewTracker name="home_page">
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
      </Head>
      <MetaSEO
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          description: metaDescription,
          type: 'video.other',
          url: 'https://s3-us-west-2.amazonaws.com/belong-web-assets/Belong.mp4',
        }}
      />
      <IntercomWidget isEnabled={INTERCOM_CONFIG.homeowner.isEnabled} />
      <div className={clsx(styles.grid, 'relative overflow-hidden')}>
        <div className={clsx(styles.gradient)} />
        <section className="mb-2xl xl:mb-3xl">
          <Hero />
        </section>
        <section className="mb-3xl md:mb-5xl flex justify-center items-center">
          <SocialProof />
        </section>
      </div>
      <section className="w-full mb-3xl md:mb-5xl" id="presentation-video">
        <BelongExplainerVideo
          autoplay
          wrapperClassName={styles.videoContainer}
          playIconStyle="green"
          videoClassName="border-none"
          buttonWrapperClassName="block"
        />
      </section>
      <section className="mb-3xl md:mb-5xl text-center container flex flex-col items-center justify-center">
        <div className={clsx('max-w-xs md:max-w-none mb-xs md:mb-sm', styles.featureTitle)}>
          <Text as="h2" variant="h2" fontWeight="semibold">
            {HOME_PAGE_STRINGS.featuresTitle}
          </Text>
        </div>
        <div className={clsx('mb-2xl w-full max-w-sm sm:max-w-md md:max-w-none', styles.featureDescription)}>
          <Text>{HOME_PAGE_STRINGS.featuresDescription}</Text>
        </div>
        <div className="mb-2xl w-full sm:hidden">
          <video width="100%" height="100%" autoPlay controls={false} muted={true} loop playsInline className="rounded">
            <source
              src="https://belong-consumer-assets.s3.us-west-1.amazonaws.com/home-page/home-chat-3x.mp4"
              type="video/mp4"
            />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
        <div className="mb-3xl w-full md:mb-5xl">
          <div className="contents sm:hidden">
            <video
              width="100%"
              height="100%"
              autoPlay
              controls={false}
              muted={true}
              loop
              playsInline
              className="rounded"
            >
              <source
                src="https://belong-consumer-assets.s3.us-west-1.amazonaws.com/home-page/home-chat-mobile-3x.mp4"
                type="video/mp4"
              />
              Sorry, your browser does not support embedded videos.
            </video>
          </div>
          <div className="hidden sm:contents">
            <video
              width="100%"
              height="100%"
              autoPlay
              controls={false}
              muted={true}
              loop
              playsInline
              className="rounded"
            >
              <source
                src="https://belong-consumer-assets.s3.us-west-1.amazonaws.com/home-page/pro-service-3x.mp4"
                type="video/mp4"
              />
              Sorry, your browser does not support embedded videos.
            </video>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-xl xl:gap-lg w-full">
          <div
            className="flex flex-col border border-solid border-gray rounded overflow-hidden relative lg:flex-1"
            style={{ backgroundColor: '#FCFFF5' }}
          >
            <div className={clsx(styles.ownRentalTitleWrapper)}>
              <div className={clsx(styles.ownRentalTitle)}>
                <Text variant="h3" fontWeight="semibold">
                  {HOME_PAGE_STRINGS.ownRentalHomeTitle}
                </Text>
              </div>
              <div className={clsx(styles.ownRentalDescription)}>
                <Text>{HOME_PAGE_STRINGS.ownRentalHomeDescription}</Text>
              </div>
            </div>
            <div className={clsx(styles.ownRentalButton)}>
              <Link href="/homeowners" variant="solid" size="large">
                {HOME_PAGE_STRINGS.ownRentalHomeButton}
              </Link>
            </div>
            <Image
              src="home-page/rent-payments.png"
              alt=""
              sizes="(max-width: 768px) 400px, 500px"
              className={clsx(styles.ownRentalImage)}
            />
          </div>
          <div
            className="flex flex-col border border-solid border-gray rounded overflow-hidden relative lg:flex-1"
            style={{ backgroundColor: '#FCFFF5' }}
          >
            <div className={clsx(styles.rentingAdvantagesContainer)}>
              <div className={clsx(styles.rentingAdvantagesTitle)}>
                <Text variant="h3" fontWeight="semibold">
                  {HOME_PAGE_STRINGS.rentingAdvantagesTitle}
                </Text>
              </div>
              <div className={clsx(styles.rentingAdvantagesDescription)}>
                <Text>{HOME_PAGE_STRINGS.rentingAdvantagesDescription}</Text>
              </div>
            </div>
            <div className={clsx(styles.rentingAdvantagesButton)}>
              <Link href="/homes" variant="solid" size="large">
                {HOME_PAGE_STRINGS.rentingAdvantagesButton}
              </Link>
            </div>
            <Image
              src="home-page/team.png"
              alt=""
              sizes="(max-width: 768px) 400px, 500px"
              className={clsx(styles.rentingAdvantagesImage)}
            />
          </div>
        </div>
      </section>
      <section className="bg-darker-navy py-3xl lg:py-5xl">
        <div className="container flex flex-col items-center mb-xl md:mb-2xl text-white" id="testimonials">
          <Text
            variant="h2"
            fontWeight="semibold"
            className={clsx(styles.testimonialsTitleMaxW, 'md:max-w-none text-center mb-sm')}
          >
            {HOME_PAGE_STRINGS.testimonialsTitle}
          </Text>
          <RatingRotator />
        </div>
        <div className="carousel-container flex justify-center">
          <TestimonialsCarousel theme="dark" blurredEnds={false} playIconStyle="green" />
        </div>
      </section>
      <div className="container flex justify-center my-2xl lg:my-3xl">
        <ReferralCard />
      </div>
      <div className="container mb-xl">
        <FaqSection />
      </div>
      <div className="container text-dark-gray  mb-3xl lg:mb-4xl">
        <Text variant="p1" fontWeight="semibold" className="text-dark-gray mb-xs">
          {HOME_PAGE_STRINGS['featuredCities.title']}
        </Text>
        <BulletList
          wrap
          size="large"
          items={featuredSet.map(({ href, displayName }) => {
            return (
              <LinkBase key={href} href={href} className="p1 hover:text-dark-navy leading-h2-sm">
                {displayName}
              </LinkBase>
            );
          })}
        />
      </div>
    </PageViewTracker>
  );
}

const CITY_PAGES_AMOUNT = 25;
const FIXED_CITIES = [
  { city: 'Oakland', stateId: 'CA' },
  { city: 'Seattle', stateId: 'WA' },
  { city: 'Redmond', stateId: 'WA' },
];

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  let featuredSetResponse: FeaturedCities;

  try {
    featuredSetResponse = await fetchFeaturedCitiesService();
  } catch (e) {
    console.error(e);
  }

  // @ts-ignore
  let featuredSet = featuredSetResponse?.data || [];
  if (featuredSet.length > 0) {
    return {
      props: { featuredSet },
    };
  }

  try {
    const coverageCities = await fetchCityListService();

    const citiesWithoutFixedCities = coverageCities.filter((city) => {
      return !FIXED_CITIES.some((fixedCity) => {
        return fixedCity.city === city.city && fixedCity.stateId === city.stateId;
      });
    });

    const featuresCities = getRandomSampleFromArray({
      array: citiesWithoutFixedCities,
      sampleSize: CITY_PAGES_AMOUNT - FIXED_CITIES.length,
    });

    const formattedFeaturesCities = [...FIXED_CITIES, ...featuresCities].map(
      (city: { city: string; stateId: string }) => ({
        href: `${BASE_PATHS.HOMEOWNER}/${kebabCase(city.city)}-${city.stateId.toLowerCase()}-property-management`,
        displayName: `${city.city}`,
      })
    );

    formattedFeaturesCities.sort((a, b) => a.displayName.localeCompare(b.displayName));
    featuredSet = formattedFeaturesCities;

    await setFeaturedCitiesService({
      data: featuredSet,
      expiresAt: futureUnixTimestamp(14),
    });
  } catch (e) {
    console.log(e);
  }

  return {
    props: { featuredSet },
  };
};

IndexPage.getLayout = (page: ReactNode): ReactNode => {
  const cookies = parseCookies();

  const comeFromMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');
  return (
    <div className="relative flex flex-col min-h-full overflow-x-hidden">
      {!comeFromMobileApp && (
        <div className="relative z-10">
          <Header variant="tertiary" />
        </div>
      )}
      <main className="flex-1">{page}</main>
      <Footer />
    </div>
  );
};
